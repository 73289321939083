import { flexRender } from "@tanstack/react-table";
import type { Cell as CellType } from "@tanstack/table-core";
import isURL from "validator/es/lib/isURL";
interface CellProps<T, D> {
  cell: CellType<T, D>;
}

const Cell = <T, D>({ cell }: CellProps<T, D>) => {
  const value = cell.getValue();

  // Handle boolean values
  if (typeof value === "boolean") {
    return (
      <td className="text-content/80 whitespace-nowrap border-x p-2 text-sm font-normal">{value ? "TRUE" : "FALSE"}</td>
    );
  }

  // Handle potential URL values
  const url = encodeAndValidateURL(value as string);
  if (url.valid) {
    return (
      <td className="text-content/80 whitespace-nowrap border-x p-2 text-sm font-normal">
        <a href={url.url} target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">
          {value as string}
        </a>
      </td>
    );
  }

  // Default rendering
  const content = flexRender(cell.column.columnDef.cell, cell.getContext());
  return <td className="text-content/80 whitespace-nowrap border-x p-2 text-sm font-normal">{content}</td>;
};

export default Cell;

function encodeAndValidateURL(inputURL: string) {
  try {
    // Ensure the URL starts with a protocol
    let url = inputURL.trim();
    if (!/^(?:https?:)?\/\//i.test(url)) {
      url = "https://" + url;
    }

    // Check if the URL is valid
    if (!isURL(url)) {
      return {
        valid: false,
        url: inputURL,
        sanitized: false,
        errors: ["Invalid URL structure"],
      };
    }

    // Parse the URL
    const parsedUrl = new URL(url);

    // Encode the pathname
    parsedUrl.pathname = parsedUrl.pathname
      .split("/")
      .map((segment) => encodeURIComponent(decodeURIComponent(segment)))
      .join("/");

    // Encode all search params
    const searchParams = new URLSearchParams();
    for (const [key, value] of parsedUrl.searchParams.entries()) {
      searchParams.append(encodeURIComponent(decodeURIComponent(key)), encodeURIComponent(decodeURIComponent(value)));
    }
    parsedUrl.search = searchParams.toString();

    // Encode the hash
    if (parsedUrl.hash) {
      parsedUrl.hash = "#" + encodeURIComponent(decodeURIComponent(parsedUrl.hash.slice(1)));
    }

    // Final validation
    const finalUrl = parsedUrl.toString();
    if (isURL(finalUrl)) {
      return {
        valid: true,
        url: finalUrl,
        sanitized: true,
      };
    } else {
      return {
        valid: false,
        url: inputURL,
        sanitized: true,
        errors: ["URL became invalid after encoding"],
      };
    }
  } catch (error) {
    return {
      valid: false,
      url: inputURL,
      sanitized: false,
      errors: ["Error processing URL"],
    };
  }
}
